<template>
  <v-row justify="center">
    <v-dialog v-model="active" v-if="visible" persistent max-width="800px">
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Terminübersicht - Editierung</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>

            <div v-if="!rawData.patient_selected || !rawData.rezept_selected" class="pb-5">
              <v-alert border="left" outlined type="info"
                v-if="rawData.gruppen_typ == 'Behandlung' && !rawData.patient_selected">
                <v-row align="center">
                  <v-col class="grow"> Patient wurde noch nicht erfasst!</v-col>
                  <v-col class="shrink">
                    <v-btn color="info" x-small @click="patientErfassen()">Erfassen</v-btn>
                  </v-col>
                </v-row>
              </v-alert>

              <v-alert border="left" outlined type="info"
                v-if="rawData.gruppen_typ == 'Behandlung' && rawData.patient_selected && !rawData.rezept_selected">
                <v-row align="center">
                  <v-col class="grow"> Rezept wurde noch nicht erfasst!</v-col>
                  <v-col class="shrink">
                    <v-btn color="info" x-small @click="rezeptErfassen()">Erfassen</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </div>

            <div v-if="rawData.gruppen_typ == 'Intern'">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="intern.titel" label="Titel" prepend-icon="mdi-format-title"
                    :error-messages="titelErrors" @input="v$.intern.titel.$touch()"
                    @blur="v$.intern.titel.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea label="Bemerkung" v-model="intern.bemerkung" rows="1"
                    prepend-icon="mdi-comment"></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-checkbox label="Ganztags?" v-model="intern.ganztag" @change="clearTimeInput"></v-checkbox>
                </v-col>

                <v-col cols="6">
                  <v-menu v-model="intern.menu.startDate" :close-on-content-click="false" transition="scale-transition"
                    offset-x min-width="auto">
                    <template v-slot:activator="{ on }">
                      <v-text-field ref="startDate" label="Startdatum" prepend-icon="mdi-calendar"
                        v-model="intern.selections.startDate" v-on="on" class="required"
                        :error-messages="startDateErrors" @input="handleInputDateText('intern', 'startDate')"
                        @focus="handleFocusDateText('intern', 'startDate')" @blur="v$.intern.startDate.$touch()"
                        placeholder="TT.MM.JJJJ"></v-text-field>
                    </template>
                    <v-date-picker v-model="intern.startDate" @input="handleInputDatePicker('intern', 'startDate')"
                      first-day-of-week="1" locale="de-de"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field ref="startTime" v-model="intern.startTime" prepend-icon="mdi-clock-outline"
                    label="Startzeit" type="time" :disabled="intern.ganztag" :error-messages="startTimeErrors"
                    @input="v$.intern.startTime.$touch()" @blur="v$.intern.startTime.$touch()"></v-text-field>
                </v-col>


                <v-col cols="6">
                  <v-menu v-model="intern.menu.endDate" :close-on-content-click="false" transition="scale-transition"
                    offset-x min-width="auto">
                    <template v-slot:activator="{ on }">
                      <v-text-field ref="endDate" label="Enddatum" prepend-icon="mdi-calendar"
                        v-model="intern.selections.endDate" v-on="on" class="required" :error-messages="endDateErrors"
                        @input="handleInputDateText('intern', 'endDate')"
                        @focus="handleFocusDateText('intern', 'endDate')" @blur="v$.intern.endDate.$touch()"
                        placeholder="TT.MM.JJJJ">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="intern.endDate" @input="handleInputDatePicker('intern', 'endDate')"
                      first-day-of-week="1" locale="de-de"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field ref="endTime" v-model="intern.endTime" prepend-icon="mdi-clock-outline" label="Endzeit"
                    type="time" :disabled="intern.ganztag" :error-messages="endTimeErrors"
                    @input="v$.intern.endTime.$touch()" @blur="v$.intern.endTime.$touch()"></v-text-field>
                </v-col>

              </v-row>


              <v-row>

                <v-col cols="12">
                  <v-autocomplete ref="mitarbeiter" label="Mitarbeiter" v-model="intern.mitarbeiter" class="required"
                    :items="mitarbeiterList" placeholder="Mitarbeiter auswählen" prepend-icon="mdi-doctor"
                    :item-text="mitarbeiter_text" item-value="id" return-object hide-no-data clearable
                    :error-messages="mitarbeiterErrors" @change="setMitarbeiterColor">
                  </v-autocomplete>
                </v-col>


                <v-col cols="8">
                  <v-dialog v-model="intern.colorDialog" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field label="Farbmarkierung" v-model="intern.color.hexa" prepend-icon="mdi-palette"
                        v-bind="attrs" v-on="on" readonly></v-text-field>
                    </template>

                    <v-card>
                      <v-color-picker v-model="intern.color" dot-size="10" show-swatches swatches-max-height="100"
                        hide-inputs>
                      </v-color-picker>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="primary-nav-btn" text color="primary" @click="intern.colorDialog = false">
                          Ok
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="4">
                  <v-text-field solo readonly disabled :background-color="intern.color.hexa"></v-text-field>
                </v-col>
              </v-row>



            </div>

            <div v-if="rawData.gruppen_typ == 'Behandlung'">

              <v-row>
                <v-col style="font-size: 16px;">
                  <span style="font-weight: 600;">Patient: </span> {{ patientText }}
                </v-col>
              </v-row>


              <v-row class="mt-3">


                <v-col cols="3" style="padding-top: 0px;">
                  <v-select label="Heilmitteltyp" v-model="treatmentType" :items="treatmentTypes"
                    prepend-icon="mdi-hand-heart" disabled readonly>
                  </v-select>
                </v-col>

                <v-col cols="8" style="padding-top: 0px;">
                  <v-autocomplete ref="heilmittel" label="Heilmittel" v-model="heilmittel" :items="heilmittelList"
                    placeholder="Heilmittel auswählen" :item-text="heilmittel_text" item-value="id" return-object
                    hide-no-data style="border-radius: 0">
                  </v-autocomplete>
                </v-col>

                <v-col class="pl-0" cols="1">
                  <v-btn elevation="2" color="primary" fab small :disabled="!heilmittel || !isRemovable(heilmittel)"
                    @click="addHeilmittelAndResetInput">
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12" class="pt-0 pb-5">
                  <v-data-table dense :headers="behandlung.behandlungen_headers" :items="behandlungen"
                    :items-per-page="10" class="elevation-1" :hide-default-footer="true" item-key="key">


                    <template v-slot:item.valid="{ item }">
                      <v-icon v-if="item.valid" small color="green">
                        mdi-check-circle
                      </v-icon>


                      <v-tooltip v-if="!item.valid" bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small color="red" v-bind="attrs" v-on="on">
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span style="white-space: pre-line">Ungültiges Heilmittel!</span>
                      </v-tooltip>


                    </template>


                    <template v-slot:item.anzahl="props">
                      <v-btn @click="openEditAnzahlDialog(props.item)" small text :disabled="!props.item.valid || anzahlDisabled(props.item)"
                        style="justify-content: right; width: 70px;">{{ props.item.anzahl }}
                        <v-icon right x-small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:item.zeiteinheit="props">
                      <v-btn @click="openEditZeiteinheitDialog(props.item)" small text
                        style="justify-content: right; width: 70px;" :disabled="!isRemovable(props.item)">{{
                          props.item.zeiteinheit }}
                        <v-icon right x-small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:item.bezeichnung="props">
                      <v-btn @click="openReplaceDialog(props.item)" small text
                        style="justify-content: left; text-transform: none;">{{ props.item.bezeichnung }}
                        <v-icon right x-small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>


                    <template slot="no-data">
                      <div :class="{ error_entry: v$.behandlungen.$error }">
                        Keine Heilmittel ausgewählt
                      </div>
                    </template>

                    <template v-slot:item.actions="{ item }">

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small @click="deleteHeilmittelItem(item)" :disabled="!isRemovable(item)"
                            v-bind="attrs" v-on="on">
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Löschen</span>
                      </v-tooltip>

                    </template>
                  </v-data-table>
                  <div v-if="unusedTreatmentErrors.length != 0" class="v-messages theme--light error--text pt-2 pl-2"
                    role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{ unusedTreatmentErrors[0] }}
                      </div>
                    </div>
                  </div>
                </v-col>

                <SeriesCreator v-if="terminmode == 'Serientermin'"></SeriesCreator>

                <v-col cols="12" class="pt-0">
                  <v-data-table dense :headers="behandlung.termine_headers" :items="termine"
                    :items-per-page.sync="behandlung.itemsPerPage" fixed-header :height="behandlung.itemsPerPage === -1 && termine.length > 10
                        ? 32 * 11 - 1
                        : undefined
                      " :single-expand="true" show-expand class="elevation-1" :hide-default-footer="true" item-key="key"
                    :item-class="row_classes" @click:row="rowClicked">
                    <template v-slot:item.mitarbeiter_str="props">
                      <v-edit-dialog :return-value="props.item.mitarbeiter_str">{{ props.item.mitarbeiter_str }}
                        <template v-slot:input>
                          <v-autocomplete label="Mitarbeiter-Auswahl" v-model="props.item.mitarbeiter"
                            :items="getAvailableMitarbeiterByTermin(props.item)" placeholder="Mitarbeiter auswählen"
                            :item-text="mitarbeiter_text" item-value="id" return-object hide-no-data :disabled="getAvailableMitarbeiterByTermin(props.item)
                                .length == 0
                              " @change="setMitarbeiterStr(props.item)">
                            >
                          </v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        {{ behandlungenSummary(item) }}
                      </td>
                    </template>

                    <template v-slot:item.start_date_str="{ item }">
                      <span class="weekday">{{
                        item.start_date_str | formatDateWeekday
                      }}</span>
                      <span>{{ item.start_date_str }}</span>
                    </template>

                    <template v-slot:item.id="{ item }">
                      {{ -1 * item.id }}
                    </template>

                    <template v-slot:item.valid="{ item }">
                      <v-icon v-if="item.valid" small color="green">
                        mdi-check-circle
                      </v-icon>

                      <v-tooltip v-if="!item.valid" bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small color="red" v-bind="attrs" v-on="on">
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span style="white-space: pre-line">{{
                          item.error_message
                        }}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon 
                      class="mx-1"
                      small 
                      @click="handleRepick(item)">
                        mdi-pencil
                      </v-icon>

                      <v-icon 
                      class="mx-1"
                      small @click="deleteTerminItem(item)" :disabled="item.status == 'Erledigt'">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>

                <v-col style="
                    text-align: right;
                    padding-top: 0px;
                    margin-bottom: -40px;
                  ">
                  <v-fab-transition>
                    <v-btn style="top: -2rem; left: 0.3rem; z-index: 100;" elevation="2" color="primary" fab small
                      :disabled="fabAddButtonDisabled" @click="hideEditTerminShowSelectHeilmittel">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-col>

                <v-col cols="12">
                  <v-textarea label="Bemerkung" v-model="behandlung.bemerkung" rows="1"
                    prepend-icon="mdi-comment"></v-textarea>
                </v-col>

              </v-row>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeWindow"> Zurück </v-btn>
          <v-btn v-if="rawData.gruppen_typ != 'Behandlung'" class="primary-nav-btn" color="primary" text
            @click="openDeleteDialog"> Löschen </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text @click="editTermine"> Speichern </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="deleteDialog" persistent max-width="450px">
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Termin löschen</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDeleteDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span>Wollen Sie alle aufgeführten Termine wirklich löschen?</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDeleteDialog"> Abbrechen </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text @click="deleteTermine"> Löschen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" persistent max-width="650px">
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Termin Validierung</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeErrorDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span>Die folgenden Termine konnten nicht validiert werden:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(value, key) in errorData" :key="key">
                    <v-expansion-panel-header>
                      <b>{{ value.name }}</b>{{ value.start }} - {{ value.end }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ul>
                        <li v-for="(error, index) in value.errors" :key="index">{{ error }}</li>
                      </ul>

                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-nav-btn" color="primary" text @click="closeErrorDialog"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="behandlung.anzahlDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Anzahl bearbeiten
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="behandlung.editAnzahl" label="Anzahl" type="text" min="1" max="100" inputmode="numeric"
            class="required" @input="v$.behandlung.editAnzahl.$touch()" @blur="v$.behandlung.editAnzahl.$touch()"
            :error-messages="anzahlErrors" v-mask="behandlung.countMask" v-on:keyup.enter="saveAnzahl"
            autofocus></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeEditAnzahlDialog">
            Abbrechen
          </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text
            :disabled="anzahlErrors.length != 0 || (behandlung.currentTreatmentItem && behandlung.editAnzahl == behandlung.currentTreatmentItem.anzahl)"
            @click="saveAnzahl">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="behandlung.dauerDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Dauer bearbeiten
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="behandlung.editDauer" label="Dauer (min)" type="text" min="0" max="120"
            inputmode="numeric" class="required" @input="v$.behandlung.editDauer.$touch()"
            @blur="v$.behandlung.editDauer.$touch()" :error-messages="dauerErrors" v-mask="'###'" suffix="min"
            v-on:keyup.enter="saveZeiteinheit" autofocus></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeEditZeiteinheitDialog">
            Abbrechen
          </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text
            :disabled="dauerErrors.length != 0 || (behandlung.currentTreatmentItem && behandlung.editDauer == behandlung.currentTreatmentItem.zeiteinheit)"
            @click="saveZeiteinheit">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="behandlung.replaceDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">
          Heilmittel ersetzen
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col>
              <v-alert small type="warning" outlined class="mb-4">
                <p class="mb-1">Sie sind dabei, das ausgewählte Heilmittel zu ersetzen.</p>
                <p class="mb-0">Dabei wird die Dauer aller offener und abgesagter Termine auf die Standarddauer des neu
                  ausgewählten Heilmittels angepasst.</p>
              </v-alert>
            </v-col>
          </v-row>

          <v-autocomplete ref="heilmittelReplace" label="Heilmittel" v-model="behandlung.editHeilmittel"
            :items="heilmittelList" placeholder="Heilmittel auswählen" :item-text="heilmittel_text" item-value="id"
            return-object hide-no-data style="border-radius: 0" autofocus class="required">
          </v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeReplaceDialog">
            Abbrechen
          </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text :disabled="!behandlung.editHeilmittel"
            @click="replaceHeilmittel">
            Ersetzen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



  </v-row>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { requiredUnless } from '@vuelidate/validators';
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";

const initialData = () => ({
  loading: false,
  deleteDialog: false,
  errorDialog: false,
  errorData: null,

  groupId: null,

  intern: {
    termin: null,

    titel: "",
    bemerkung: "",
    ganztag: false,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    mitarbeiter: null,
    color: {
      hexa: "#B43962FF",
    },

    menu: {
      startDate: false,
      endDate: false,
    },
    selections: {
      startDate: null,
      endDate: null,
    },

    colorDialog: false
  },

  behandlung: {
    patient: null,
    itemsPerPage: -1,
    bemerkung: "",

    currentTreatmentItem: null,

    anzahlDialog: false,
    editAnzahl: null,
    dauerDialog: false,
    editDauer: null,

    replaceDialog: false,
    editHeilmittel: null,

    behandlungen_headers: [
      {
        text: "Bezeichnung",
        align: "start",

        value: "bezeichnung",
      },
      { text: "Anzahl", value: "anzahl" },
      { text: "Dauer", value: "zeiteinheit" },
      { text: "Status", value: "valid", sortable: false },
      { text: "Aktionen", value: "actions", sortable: false },
    ],

    termine_headers: [
      {
        text: "Nr.",
        align: "start",
        value: "id",
        sortable: false,
      },
      {
        text: "Datum",
        value: "start_date_str",
        sortable: false,
      },
      {
        text: "Zeit",
        value: "start_time",
        sortable: false,
      },
      {
        text: "Mitarbeiter",
        value: "mitarbeiter_str",
        sortable: false,
      },

      {
        text: "Status",
        value: "valid",
        sortable: false,
      },
      { text: "", value: "data-table-expand" },
      { text: "Aktionen", value: "actions", sortable: false },
    ],


    // Treatment amount dialog number input
    countMask: {
      mask: "PNN",
      tokens: {
        P: {
          pattern: /[1-9]/,
        },
        N: {
          pattern: /[0-9]/,
        },
      },
    },



  }
})

function startDateIsBeforeEndDate(date) {
  if (this.termintyp == "Intern" && date && this.intern.endDate) {
    return moment(date).isSameOrBefore(moment(this.intern.endDate));
  }
  return true;
}

function startDateTimeIsBeforeEndDateTime(time) {
  // check if start date and end date are present
  if (this.termintyp == "Intern" && this.intern.startDate && this.intern.endDate && time && this.intern.endTime) {
    let start = moment(this.intern.startDate + " " + time, "YYYY-MM-DD HH:mm");
    let end = moment(this.intern.endDate + " " + this.intern.endTime, "YYYY-MM-DD HH:mm");
    return start.isBefore(end);
  }
  return true;
}

function isInRange(min, max) {
  return function (value) {
    if (this.termintyp == "Behandlung" && this.behandlung.currentTreatmentItem) {
      return value >= min && value <= max;
    }
    return true;
  };
}

function isValidAnzahl(value) {
  if (this.termintyp == "Behandlung" && this.behandlung.currentTreatmentItem) {
    let in_use = this.$store.getters["overlays/behandlungenInUse"](
      this.behandlung.currentTreatmentItem
    );
    return Number(value) >= in_use;
  }
  return true;
}

const isEmptyListOrNull = (list) => {
  return list == null || list.length == 0;
};


export default {
  name: "TerminEdit",
  props: ["switchToTermin"],

  setup() {
    return { v$: useVuelidate() }
  },
  validations: {
    intern: {
      titel: {
        required: requiredUnless(function () {
          return this.termintyp == "Behandlung";
        }),
      },
      startTime: {
        required: requiredUnless(function () {
          return this.intern.ganztag || this.termintyp == "Behandlung";
        }),
        startDateTimeIsBeforeEndDateTime,
      },
      endTime: {
        required: requiredUnless(function () {
          return this.intern.ganztag || this.termintyp == "Behandlung";
        }),
        // TODO: check if end_time is after start_time (if both are set) but include dates
      },
      startDate: {
        required: requiredUnless(function () {
          return this.termintyp == "Behandlung";
        }),
        startDateIsBeforeEndDate
      },
      endDate: {
        required: requiredUnless(function () {
          return this.termintyp == "Behandlung";
        }),
      },
      mitarbeiter: {
        required: requiredUnless(function () {
          return this.termintyp == "Behandlung";
        }),
      },

    },

    behandlung: {
      editAnzahl: {
        required: requiredUnless(function () {
          return this.termintyp != "Behandlung" || !this.behandlung.currentTreatmentItem;
        }),
        isValidAnzahl,
        isInRange: isInRange(1, 100),
      },

      editDauer: {
        required: requiredUnless(function () {
          return this.termintyp != "Behandlung" || !this.behandlung.currentTreatmentItem;
        }),
        isInRange: isInRange(0, 120),
      },

      verbleibende_behandlungen: {
        isEmptyListOrNull: function () {
          if (this.termintyp != "Behandlung") {
            return true;
          }
          return isEmptyListOrNull(this.verbleibende_behandlungen);
        },

      },
    },
    termine: {
      required: requiredUnless(function () {
        return this.termintyp != "Behandlung";
      }),
    },

    behandlungen: {
      required: requiredUnless(function () {
        return this.termintyp != "Behandlung";
      }),
    },


  },

  filters: {

    formatDate(value) {
      return value ? moment(String(value)).format("DD.MM.YYYY") : "";
    },

    formatDateWeekday(value) {
      if (!value) return "";
      moment.locale("de");
      return moment(String(value), "DD.MM.YYYY").format("ddd");
    },
  },


  data: function () {
    return initialData();
  },

  computed: {
    ...mapGetters("mitarbeiter", ["getAvailableMitarbeiterByTermin"]),

    active() {
      return this.$store.getters["overlays/editIsActive"];
    },

    visible() {
      return this.$store.getters["overlays/editIsVisible"];
    },

    termine: {
      get() {
        return this.$store.state.overlays.edit.selected.termine;
      },

      set(value) {
        this.$store.commit("overlays/updateTermine", value, {
          root: true,
        });
      },
    },


    termintyp: {
      get() {
        return this.$store.state.overlays.edit.selected.termintyp;
      },

      set(value) {
        this.$store.commit("overlays/updateTermintyp", value, { root: true });
      },
    },


    rawData() {
      return this.$store.state.overlays.edit.rawData;
    },

    mitarbeiterList() {
      return this.$store.getters["mitarbeiter/getMitarbeiter"];
    },

    // Errors
    titelErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.titel;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Titel ist leer!");
      }

      return errors;
    },

    startDateErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.startDate;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Startdatum ist leer!");
        entry.startDateIsBeforeEndDate.$invalid && errors.push("Startdatum ist nach dem Enddatum!");
      }

      return errors;
    },

    startTimeErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.startTime;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Startzeit ist leer!");
        entry.startDateTimeIsBeforeEndDateTime.$invalid && errors.push("Startzeit ist nach der Endzeit!");
      }

      return errors;
    },

    endDateErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.endDate;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Enddatum ist leer!");
      }

      return errors;
    },

    endTimeErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.endTime;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Endzeit ist leer!");
      }

      return errors;
    },

    mitarbeiterErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.intern.mitarbeiter;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Mitarbeiter ist leer!");
      }

      return errors;
    },


    anzahlErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.behandlung.editAnzahl;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Keine Anzahl angegeben!");
        entry.isInRange.$invalid &&
          errors.push("Anzahl muss zwischen 1 und 100 liegen!");
        entry.isValidAnzahl.$invalid &&
          errors.push("Eine größere Anzahl ist bereits in Verwendung!");
      }
      return errors;
    },

    dauerErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.behandlung.editDauer;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Keine Dauer angegeben!");
        entry.isInRange.$invalid &&
          errors.push("Dauer muss zwischen 0 und 120 min liegen!");
      }
      return errors;
    },


    behandlungen: {
      get() {
        return this.$store.state.overlays.edit.selected.behandlungen;
      },

      set(value) {
        this.$store.commit("overlays/updateBehandlungen", value, {
          root: true,
        });
      },
    },


    unusedTreatmentErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.behandlung.verbleibende_behandlungen;

        if (!entry.$dirty) return errors;

        // This should only be invalid if there are still unused treatments left or
        // if the appointment type is not for a treatment but an internal appointment
        entry.isEmptyListOrNull.$invalid &&
          errors.push("Nicht alle Behandlungen wurden eingeplant!");
      }

      return errors;
    },


    verbleibende_behandlungen() {
      if (this.termintyp == "Behandlung") {
        return this.$store.getters["overlays/editGetRemainingBehandlungen"];
      }
      return [];
    },


    termintypes() {
      return this.$store.getters["overlays/getTerminTypes"];
    },

    terminmodes() {
      return this.$store.getters["overlays/getTerminModes"];
    },

    terminmode: {
      get() {
        return this.$store.state.overlays.edit.selected.terminmode;
      },

      set(value) {
        this.$store.commit("overlays/updateTerminmode", value, { root: true });
      },
    },

    treatmentTypes() {
      const patient = this.$store.state.overlays.edit.selected.patient;
      const leTyp = this.$store.getters["kunde/getKunde"].le_typ;
      const ktTyp = patient.incomplete ? null : this.patient.kt_typ;

      return this.$store.getters["overlays/getTreatmentTypes"](ktTyp, leTyp);
    },

    treatmentType: {
      get() {
        return this.$store.state.overlays.edit.selected.treatmentType;
      },
    },

    patientText() {
      const patient = this.$store.state.overlays.edit.selected.patient;

      let text = "";

      if (patient) {
        if (patient.kt_typ) {
          text += "(" + patient.kt_typ + ") ";
        }

        text += patient.name + ", " + patient.vorname + " (" + this.formatDate(patient.geburtsdatum) + ")";
      }

      return text
    },






    fabAddButtonDisabled() {
      const emptyTreatment = this.behandlungen.length === 0;
      const noRemainingTreatments = this.verbleibende_behandlungen.length === 0;
      const isSeries = this.terminmode === "Serientermin";
      const noAppointmentSelected = this.termine.length === 0;

      return (
        emptyTreatment ||
        noRemainingTreatments ||
        (isSeries && noAppointmentSelected)
      );
    },


    heilmittel: {
      get() {
        return this.$store.state.overlays.edit.selected.heilmittel;
      },

      set(value) {
        this.$store.commit("overlays/updateHeilmittel", value, { root: true });
      },
    },




    heilmittelList() {
      let entries = [];

      if (["Standard", "Blanko"].includes(this.treatmentType)) {
        entries = this.$store.getters["zeiteinheiten/getZeiteinheiten"];
      } else {
        entries = this.$store.getters[
          "zeiteinheiten/getVollprivatZeiteinheiten"
        ];
      }
      return entries;
    },

    patient: {
      get() {
        return this.$store.state.overlays.edit.selected.patient;
      },
    },





  },
  methods: {
    ...mapMutations("overlays", [
      "clearTimeInput", //TODO: replace with a component method (instead of a vuex mutation)
      "openAddRezept",
      "hideEditTerminShowSelectHeilmittel",
      "hideEditTerminShowSelectHeilmittelRepick",
      "removeTermin",
      "recheckTermine",
      "addHeilmittel",
      "removeHeilmittel",
      "openAddPatient",
      "openAddRezept",
      "openExcessPaymentNotification"
    ]),

    ...mapActions("termine", ["deleteTerminGruppe"]),
    ...mapActions("overlays", ["closeEditTerminandReset"]),



    mitarbeiter_text: (item) => item.name + ", " + item.vorname,
    heilmittel_text: (item) => item.bezeichnung,


    formatDate(value) {
      return value ? moment(String(value)).format("DD.MM.YYYY") : "";
    },

    handleRepick(item){
      this.switchToTermin(item);
      this.hideEditTerminShowSelectHeilmittelRepick(item);
    },

    patientErfassen() {
      this.openAddPatient(this.rawData);
      this.closeEditTerminandReset();
    },

    rezeptErfassen() {
      this.openAddRezept(this.rawData);
      this.closeEditTerminandReset();
    },

    closeWindow() {
      // Reset errors
      this.v$.$reset();

      // Reset data
      Object.assign(this.$data, initialData());

      // Close window
      this.closeEditTerminandReset();
    },

    async editTermine() {
      // Validate
      this.v$.$touch();

      if (this.v$.$errors.length == 0) {
        let data = null;

        if (this.termintyp == 'Intern') {
          data = {
            id: this.groupId,
            bemerkung: this.intern.bemerkung,
            kunde: this.intern.termin.kunde,
            patient: null,
            incompletePatientData: null,
            termintyp: "Intern",
            termine: [
              {
                id: this.intern.termin.id,
                name: this.intern.titel,
                start: null,
                end: null,
                ganztag: this.intern.ganztag,
                mitarbeiter: this.intern.mitarbeiter ? this.intern.mitarbeiter.id : null,
                color: this.intern.color.hex,
                behandlungen: null,
              }
            ]
          }

          // Create moment objects from date string
          let s = moment(this.intern.startDate).utc(true);
          let e = moment(this.intern.endDate).utc(true);

          if (!this.intern.ganztag) {
            s.hour(this.intern.startTime.substring(0, 2)).minute(this.intern.startTime.substring(3));
            e.hour(this.intern.endTime.substring(0, 2)).minute(this.intern.endTime.substring(3));
          }
          else {
            // Set start and end to beginning and end of the day
            s.hour(0).minute(0).second(0);
            e.hour(23).minute(59).second(59);
          }

          // Convert to ISO string
          data.termine[0].start = s.toISOString();
          data.termine[0].end = e.toISOString();
        }
        else {
          data = await this.$store.dispatch("overlays/getAppointmentsData", null, { root: true });
          // Do not forget to set the group id so that the server knows which group to update/old appointments to delete
          data.id = this.groupId;
          data.bemerkung = this.behandlung.bemerkung
        }

        // Send data to server
        let response_data = await this.$store.dispatch("termine/updateAppointments", data);

        // Response data could be an error list or an object with a warning property
        if (response_data.warnings) {
          if (response_data.warnings.excessPayment) {
            this.openExcessPaymentNotification(response_data.warnings);
          }
          this.closeWindow();

        }

        else if (Object.keys(response_data).length > 0){
          this.errorData = {}

          for (const [index, termin] of data.termine.entries()) {
            if (response_data[index]) {
              this.errorData[index] = {
                name: termin.name,
                start: moment(termin.start.slice(0, -1)).format("DD.MM.YYYY HH:mm"),
                end: moment(termin.end.slice(0, -1)).format("DD.MM.YYYY HH:mm"),
                errors: response_data[index]
              }

              // Set error message
              termin.error = response_data[index];
            }
          }

          // Show error dialog
          this.errorDialog = true;
        }
        else {
          // Close window
          this.closeWindow();
        }
      }
    },

    async deleteTermine() {
      await this.deleteTerminGruppe(this.groupId);
      this.closeDeleteDialog();
      this.closeWindow();
    },

    openDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    closeErrorDialog() {
      this.errorDialog = false;
    },

    clearTimeInput() {
      this.intern.startTime = null;
      this.intern.endTime = null;
    },

    handleInputDateText(type, key) {
      let isValid = false

      // Format date text
      if (this[type].selections[key]) {
        let selection_date = this[type].selections[key];
        let m = moment(selection_date, "DD.MM.YYYY", true);
        if (m.isValid()) {
          // Set formatted date

          this[type][key] = m.format("YYYY-MM-DD");
          // Set valid flag
          isValid = true;

          // Close menu
          setTimeout(() => {
            this[type].menu[key] = false;
          }, 200);
        }
      }

      // Reset date text if invalid
      if (!isValid) {
        this[type][key] = null;
      }

      // Touch validation
      this.v$[type][key].$touch();
    },

    handleFocusDateText(type, key) {
      let menu_names = ["startDate", "endDate"].filter((item) => item != key);

      setTimeout(() => {
        // Close other menus
        menu_names.forEach((menu_name) => {
          this[type].menu[menu_name] = false;
        });

        // Open focused menu
        this[type].menu[key] = true;
      }, 200);
    },

    deleteHeilmittelItem(item) {
      this.v$.behandlungen.$touch();
      this.removeHeilmittel(item.key);
    },


    handleInputDatePicker(type, key) {
      let focusLkp = {
        startDate: ["startTime", "endDate"],
        endDate: ["endTime", "mitarbeiter"],
      }

      // Set date text
      this[type].selections[key] = moment(this[type][key]).format("DD.MM.YYYY");

      // Close menu
      this[type].menu[key] = false;

      // Focus next input
      const resolutionOrder = focusLkp[key];
      if (resolutionOrder) {
        // Find first existing ref and focus it and stop
        for (var entry in resolutionOrder) {
          let ref = resolutionOrder[entry];
          if (this.$refs[ref]) {
            this.$refs[ref].focus();
            break;
          }
        }
      }
    },



    rowClicked(d1, d2) {
      if (d2.isExpanded) {
        d2.expand(false);
      } else {
        d2.expand(true);
      }
    },



    setMitarbeiterColor() {
      if (this.intern.mitarbeiter) {
        this.intern.color.hex = this.intern.mitarbeiter.color;
        this.intern.color.hexa = this.intern.mitarbeiter.color + "FF";
      }
    },


    findTimeUnitEntry(name) {
      return this.heilmittelList.find((item) => item.bezeichnung == name);
    },


    calculateBehandlungen() {
      let collection = {};
      let appointments = this.$store.state.overlays.edit.rawData.termine;
      let key = 1;

      for (const appointment in appointments) {
        const behandlungen = appointments[appointment].behandlungen;

        for (const behandlung in behandlungen) {
          const [name, zeiteinheit] = behandlungen[behandlung]

          if (collection[name]) {
            collection[name].anzahl += 1;

            // This is a special case that can only occur if the treatment was added to an already completed appointment and the treatment duration was set to 0min.
            // e.g. The duration of already completed appointments should not be changed. Adding a new treatment with a 0min duration achieves this.
            if (collection[name].zeiteinheit < zeiteinheit) {
              collection[name].zeiteinheit = zeiteinheit;
            }
          }
          else {
            const relatedTimeUnit = this.findTimeUnitEntry(name);

            let hm = null
            // relatedTimeUnit can be undefined if the time unit is not found e.g. it is not part of the list (after filtering)
            if (relatedTimeUnit) {
              hm = { ...relatedTimeUnit };
              hm.anzahl = 1;
              hm.valid = true;
              hm.key = key;
              hm.remaining = 0
            }
            else {
              hm = {
                abkuerzung: null,
                bezeichnung: name,
                id: null,
                richt_info: null,
                typ: null,

                zeiteinheit: zeiteinheit,
                anzahl: 1,
                valid: false,
                key: key,
                remaining: 0
              }
            }

            key += 1;

            collection[name] = hm;
          }

        }


      }

      this.behandlungen = Object.values(collection);
    },

    async rebuildTermineObjects(rawTermine) {
      let termine = [];
      let key = 1;
      let id = -1;

      for (let entry of rawTermine) {

        let mitarbeiter = this.$store.getters["mitarbeiter/getMitarbeiterbyId"](entry.mitarbeiter);

        let termin = {
          key: key,
          id: id,
          start_date: moment(entry.start),
          start_date_str: moment(entry.start).format("DD.MM.YYYY"),
          start_time: moment(entry.start).format("HH:mm"),
          end_date: moment(entry.end),
          end_time: moment(entry.end).format("HH:mm"),
          mitarbeiter: mitarbeiter,
          mitarbeiter_str: this.mitarbeiter_text(mitarbeiter),
          status: entry.status,
          valid: true,
          error_message: "",
          available_mitarbeiter: new Set(),
          behandlungen: [],
          patient: entry.patient_data.patient,
        }

        if (entry.status == "Offen") {
          termin.status = "Temporär";
        }

        // Add behandlungen
        let seenBehandlungen = new Set();

        for (let terminTreatment of entry.behandlungen) {
          for (let currentTreatment of this.behandlungen) {

            if (currentTreatment.bezeichnung == terminTreatment[0] && !seenBehandlungen.has(currentTreatment.key)) {
              termin.behandlungen.push(currentTreatment);
              seenBehandlungen.add(currentTreatment.key);
            }

          }
        }

        termine.push(termin);
        key += 1;
        id -= 1;
      }

      return termine;

    },


    async initTerminData() {
      this.groupId = this.rawData.id
      this.termintyp = this.rawData.gruppen_typ;
      if (this.termintyp == "Intern") {

        // Set termin
        this.termine = this.rawData.termine;
        this.intern.termin = this.termine[0];

        // Set values
        this.intern.titel = this.intern.termin.name;
        this.intern.bemerkung = this.intern.termin.bemerkung;
        this.intern.ganztag = this.intern.termin.ganztag;

        this.intern.color.hex = this.intern.termin.color;
        this.intern.color.hexa = this.intern.termin.color + "FF";

        // Find mitarbeiter based on the category name
        this.intern.mitarbeiter = this.mitarbeiterList.find((item) => this.mitarbeiter_text(item) == this.intern.termin.category);

        // Set date and time values
        if (this.intern.ganztag) {
          // start/end will only contain date without the time part
          this.intern.startDate = this.intern.termin.start;
          this.intern.selections.startDate = moment(this.intern.termin.start).format("DD.MM.YYYY");

          this.intern.endDate = this.intern.termin.end;
          this.intern.selections.endDate = moment(this.intern.termin.end).format("DD.MM.YYYY");
        }
        else {
          // start/end will contain date and time e.g. 2021-03-01T08:00:00
          let sm = moment(this.intern.termin.start, "YYYY-MM-DDTHH:mm:ss", true);
          this.intern.startDate = sm.format("YYYY-MM-DD");
          this.intern.selections.startDate = sm.format("DD.MM.YYYY");
          this.intern.startTime = sm.format("HH:mm");

          let em = moment(this.intern.termin.end, "YYYY-MM-DDTHH:mm:ss", true);
          this.intern.endDate = em.format("YYYY-MM-DD");
          this.intern.selections.endDate = em.format("DD.MM.YYYY");
          this.intern.endTime = em.format("HH:mm");
        }
      }

      else if (this.termintyp == "Behandlung") {
        await this.refetchTimeUnits();
        this.calculateBehandlungen();
        this.termine = await this.rebuildTermineObjects(this.rawData.termine);

        this.behandlung.bemerkung = this.rawData.bemerkung;

        await this.recheckTermine();
      }
    },


    openReplaceDialog(item) {
      this.behandlung.currentTreatmentItem = item;
      this.behandlung.replaceDialog = true;
    },

    closeReplaceDialog() {
      this.behandlung.currentTreatmentItem = null;
      this.behandlung.editHeilmittel = null;
      this.behandlung.replaceDialog = false;
    },

    openEditAnzahlDialog(item) {
      this.behandlung.currentTreatmentItem = item;
      this.behandlung.editAnzahl = item.anzahl;
      this.behandlung.anzahlDialog = true;
    },

    closeEditAnzahlDialog() {
      this.behandlung.currentTreatmentItem = null;
      this.behandlung.editAnzahl = null;
      this.behandlung.anzahlDialog = false;
    },

    openEditZeiteinheitDialog(item) {
      this.behandlung.currentTreatmentItem = item;
      this.behandlung.editDauer = item.zeiteinheit;
      this.behandlung.dauerDialog = true;
    },

    closeEditZeiteinheitDialog() {
      this.behandlung.currentTreatmentItem = null;
      this.behandlung.editDauer = null;
      this.behandlung.dauerDialog = false;
    },

    saveZeiteinheit() {
      if (this.dauerErrors.length == 0) {
        this.behandlung.currentTreatmentItem.zeiteinheit = Number(this.behandlung.editDauer);
        this.closeEditZeiteinheitDialog();
      }
    },

    saveAnzahl() {
      if (this.anzahlErrors.length == 0) {
        this.behandlung.currentTreatmentItem.anzahl = Number(this.behandlung.editAnzahl);
        this.closeEditAnzahlDialog();
      }
    },

    replaceHeilmittel() {
      if (this.behandlung.editHeilmittel && this.behandlung.currentTreatmentItem) {

        let oldTreatment = this.behandlung.currentTreatmentItem;
        let newTreatment = {
          ...this.behandlung.editHeilmittel,
          key: oldTreatment.key,
          anzahl: oldTreatment.anzahl,
          remaining: oldTreatment.remaining,
          valid: true
        };

        // Update the treatment in the list
        this.behandlungen = this.behandlungen.map((item) => {
          if (item.key == oldTreatment.key) {
            return newTreatment;
          }
          return item;
        });

        // Update all appointments 
        for (let appointment of this.termine) {
          // Find and replace the previous treatment in the appointment
          appointment.behandlungen = appointment.behandlungen.map((item) => {
            if (item.key == oldTreatment.key) {
              return newTreatment;
            }
            return item;
          })

          // Only update appointment duration if it is not completed
          if (appointment.status != "Erledigt") {

            let m = 0;
            for (let beh of appointment.behandlungen) {
              m += parseInt(beh.zeiteinheit, 10);
            }

            let end = appointment.start_date.clone().add(m, "minutes");
            appointment.end_date = end;
            appointment.end_time = end.format("HH:mm");
          }

        }
      }
      this.recheckTermine();
      this.closeReplaceDialog();
    },

    deleteTerminItem(item) {
      this.v$.termine.$touch();
      this.removeTermin(item.key);
      this.recheckTermine();
    },

    row_classes(item) {
      if (!item.valid) {
        return "error_row";
      }

      else if (item.status == "Erledigt") {
        return "completed_row";
      }

      else if (item.status == "Abgesagt") {
        return "cancelled_row";
      }
    },

    behandlungenSummary(item) {
      let summary_parts = [];
      let counted_parts = [];
      let time_sum = 0;

      for (const beh of item.behandlungen) {
        summary_parts.push(beh.bezeichnung);
        time_sum += parseInt(beh.zeiteinheit, 10);
      }

      const map = summary_parts.reduce(
        (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
        new Map()
      );

      [...map.entries()].forEach((element) => {
        if (element[1] > 1) {
          counted_parts.push(element[1] + "x " + element[0]);
        } else {
          counted_parts.push(element[0]);
        }
      });

      // Time info
      let m = time_sum % 60;
      let h = (time_sum - m) / 60;

      return (
        h.toString() +
        "h " +
        (m < 10 ? "0" : "") +
        m.toString() +
        "min - " +
        counted_parts.join(" + ")
      );
    },

    setMitarbeiterStr(item) {
      item.mitarbeiter_str =
        item.mitarbeiter.name + ", " + item.mitarbeiter.vorname;

      this.recheckTermine();
    },

    addHeilmittelAndResetInput() {
      this.addHeilmittel();
      this.heilmittel = null;
    },


    isRemovable(item) {
      const locked = ["Physiotherap. Diagnostik (PD)", "Versorgungspauschale Blanko-VO", "Analyse ergotherap. Bedarf"]
      if (item){
        return this.$store.getters["overlays/isRemovable"](item) && !locked.includes(item.bezeichnung);
      }
      return false;
    },


    anzahlDisabled(item) {
      const locked = ["Versorgungspauschale Blanko-VO", "Physiotherap. Diagnostik (PD)", "Analyse ergotherap. Bedarf"]
      return locked.includes(item.bezeichnung);
    },


    async refetchTimeUnits() {

      const patient = this.$store.state.overlays.edit.selected.patient;

      if (this.treatmentType === "Vollprivat") {
        await this.$store.dispatch("zeiteinheiten/GetVollprivatZeiteinheitenData");
      } else {
        // For both "Standard" and "Blanko" types
        const ktType = patient.incomplete ? null : patient.kt_typ;
        const blanko = this.treatmentType === "Blanko";
        const headerId = this.rawData.rezeptId ? this.rawData.rezeptId : null;

        await this.$store.dispatch(
          "zeiteinheiten/GetTarifZeiteinheitenData",
          {
            ktType: ktType,
            blanko: blanko,
            headerId: headerId,
          }
        );
      }
    },
  },

  watch: {
    active: function (val) {
      if (val) {
        this.initTerminData();
      }
    },

    heilmittel: function( newValue ) {
      if (newValue) {
        const locked = ["Physiotherap. Diagnostik (PD)", "Versorgungspauschale Blanko-VO", "Analyse ergotherap. Bedarf"]
        if (locked.includes(newValue.bezeichnung)){
          this.heilmittel = null;
          this.$refs.heilmittel.reset()
        }
      }
    },


  },

  mounted() { },
};
</script>
<style>
.error_row {
  background-color: #ff8f8f33;
}

.completed_row {
  background-color: #8fff8f33;
}

.cancelled_row {
  background-color: #ffd28f33;
}

.error_entry {
  color: red;
}
</style>
